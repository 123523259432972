import React, { Fragment, useEffect } from "react"
import Navbar from "../components/Navbar"
import "../css/about.css"
import ReactPlayer from "react-player"
import Footer from "../components/Footer"
import Img1 from "../images/Bck8.jpg"
import Img1Min from "../images/Bck8-min.jpg"
import "aos/dist/aos.css"
import AOS from "aos"
import Layout from "../components/layout"
import ProgressiveImage from "react-progressive-image-loading"
import Head from "../components/head"

const AboutPage = () => {
  useEffect(() => {
    // Aos
    AOS.init({
      once: true,
    })
  })
  return (
    <Layout>
      <Head title="O Nama" />
      <section className="about-us">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">O nama</h1>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="outter-content">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              className="inner-content"
            >
              <p>
                <span className="bold">Dak Comerc DOO</span> je porodična
                kompanija osnovana 26.01.2001. godine u Novom Sadu. Osnovna
                deltanost kompanije je trgovina i proizvodnja iz oblasti crne
                metalurgije.
                <br />
                <br />
                Sedište firme se nalazi u Novom Sadu u ulici Temerinski put 21
                gde se nalaze poslovna zgrada i manji magacin robe i gotovih
                proizvoda. Takođe, na udaljenosti od 1km nalazi se još jedan
                magacin koji se prostire na 7.200m2 u ulici Arkadija Varađanina
                63 gde se vrši šleperski utovar i istovar.
                <br />
                Fabrika šavnih cevi i servisni centar su smešteni u Gornjem
                Milanovcu na površini od 13.000 m2.
              </p>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              id="video"
              className="inner-content"
            >
              <ReactPlayer
                id="player"
                url="https://stefracic.wistia.com/medias/63xdgzg43p"
                controls
                width="700px"
                height="390px"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        data-aos="fade-up"
        data-aos-duration="1000"
        className="about-us-2"
      >
        <div className="container has-text-white has-text-centered">
          <p className="is-size-5-desktop is-size-6-mobile">
            Saradnja postoji sa železarama iz cele evrope (Srbija, Mađarska,
            Austrija, Ukrajina, Poljska), takođe i sa drugim evropskim fabrikama
            iz naše delatnosti. Redovan smo uvoznik robe iz Italije, Poljske,
            Makedonije, Turske i drugih zemalja.
          </p>
        </div>
      </section>
      <section className="about-us-3">
        <div className="container outter-content">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="inner-content"
          >
            <ProgressiveImage
              preview={Img1Min}
              src={Img1}
              render={src => <img src={src} />}
            />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            className="inner-content"
          >
            <p>
              Sa pozicijom velikog trajdera donosi se odluka krajem 2014. godine
              da se uloži u proizvodni pogon u Gornjem Milanovcu i od 2015.
              godine <br />
              Dak Comerc sa pozicije velikog trgovca prelazi u proizvođača
              šavnih cevi, PVC ojačanja za prozore. U Gornjem Milanovcu se
              proizvode i CD/UD profili za suvu gradnju, seku se table na meru
              iz rolni na tzv. ravnalici, slitovanje rolni (poprečno sečenje) na
              sliter mašini, i stranice za kamionske prikolice popularne
              “Zmajevke”.
            </p>
          </div>
        </div>
      </section>
      <section
        data-aos="zoom-in"
        data-aos-duration="1000"
        className="about-us-4"
      >
        <div className="container has-text-centered">
          <p>
            Pozicioniranjem na domaćem tržištu sa kvalitetom i konkurentnom
            cenom Dak Comerc svake godine sve više izvozi svojih proizvoda u
            zemlje u okruženje. Konstanta saradnja se ostvaruje sa kompanijama u
            Bosni, Crnoj Gori, a od prošle godine otvoreno je i Hrvatsko
            tržište.
            <br />
            <br />
            Godine 2019. donosi se odluka o ulaganju u modernizaciju proizvodnih
            linija za cevi i kupovina nove linije za sečenje lima iz kotura. Kao
            konačni rezultat ovih ulaganja očekujemo da se proizvodnja poveća za
            50% i dobije još bolji kvalitet.
          </p>
        </div>
      </section>
      <section
        data-aos="fade-up"
        data-aos-duration="1000"
        className="about-us-5"
      >
        <div className="container has-text-centered has-text-white">
          <p className="is-size-5-desktop is-size-6-mobile">
            “Naš razvojni put još uvek traje i nastavićemo da oblikujemo
            budućnost, jer od nas sve počinje.”
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
